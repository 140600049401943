/** @jsxImportSource @emotion/react */

import 'twin.macro';
import { FC } from 'react';
import { localeEN, localeNL } from '@/utils/locale';
import { useLocale } from '@/hooks/useLocal';
import tw from 'twin.macro';
import Image from 'next/image';
import nlFlag from './assets/nl.png';
import enFlag from './assets/en.png';
import { addLocaleToPath, TLocales } from '@sal-solution/utils';
import { usePathname } from 'next/navigation';
import Link from 'next/link';

const FlagImage = tw(Image)`w-6 h-full`;

export const Navigation: FC = () => {
  const locale = useLocale(NavigationLocale);
  const pathname = usePathname();

  const changeLocale = (locale: TLocales) => {
    document.cookie = `NEXT_LOCALE=${locale}; path=/`;
    const path = addLocaleToPath(pathname, locale);
    window.location.href = path;
  };

  return (
    <div tw="navbar bg-base-100 z-20 sticky top-0">
      <div tw="flex-1">
        <Link href="/" tw="btn btn-ghost text-xl">
          <Image
            width="50"
            height="50"
            src="/assets/icons/icon.png"
            alt="vergelijker-logo"
          />{' '}
        </Link>
      </div>
      <div tw="flex-none">
        <ul tw="menu menu-horizontal px-1">
          <li>
            <Link href="/contact">{locale.contact}</Link>
          </li>
          <li>
            <Link href="/blog">{locale.blog}</Link>
          </li>
          <li>
            <details>
              <summary>{locale.lang}</summary>
              <ul tw="p-2 bg-base-100 rounded-t-none">
                <li>
                  <a>
                    <FlagImage
                      src={nlFlag}
                      alt="flag-nl"
                      width={24}
                      height={24}
                      onClick={() => changeLocale(localeNL)}
                    />
                  </a>
                </li>
                <li>
                  <a>
                    <FlagImage
                      src={enFlag}
                      alt="flag-en"
                      width={24}
                      height={24}
                      onClick={() => changeLocale(localeEN)}
                    />
                  </a>
                </li>
              </ul>
            </details>
          </li>
        </ul>
      </div>
    </div>
  );
};

const NavigationLocale = {
  [localeEN]: {
    contact: 'Contact',
    lang: 'Language',
    blog: 'Blog',
  },
  [localeNL]: {
    contact: 'Contact',
    lang: 'Taal',
    blog: 'Blog',
  },
};
