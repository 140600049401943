/** @jsxImportSource @emotion/react */

import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import tw from 'twin.macro';
import packageJson from '@/package.json';
import { localeEN, localeNL } from '@/utils/locale';
import { useLocale } from '@/hooks/useLocal';

const Foot = tw.footer`footer px-10 py-10 bg-base-200 text-base-content lg:(grid grid-cols-12 px-0)`;
const ASide = tw.aside`lg:(col-start-3 col-end-6)`;
const NavM = tw.nav`lg:(col-start-8 col-end-10)`;
const NavR = tw.nav`lg:(col-start-10 col-end-12)`;
const FooterTitle = tw.h6`footer-title`;
const FooterLink = tw(Link)`link link-hover`;
export const Footer: FC = () => {
  const locale = useLocale(FooterLocale);

  return (
    <Foot>
      <ASide>
        <Image
          width="50"
          height="50"
          src="/assets/icons/icon.png"
          alt="Housing Logo"
        />
        <p>
          {/* {locale.since} */}
          {/* <br /> */}
          {packageJson.version}
        </p>
      </ASide>
      {/* <nav tw="lg:(col-start-6 col-end-8)">
        <FooterTitle >{locale.services}</FooterTitle>
        <FooterLink href="/sign-up">
          {locale.subscribe}
        </FooterLink>
      </nav> */}
      <NavM>
        <FooterTitle>{locale.company}</FooterTitle>
        <FooterLink href="/contact">{locale.contact}</FooterLink>
      </NavM>
      <NavR>
        <FooterTitle>{locale.legal}</FooterTitle>
        <FooterLink href="/terms">{locale.terms}</FooterLink>
        <FooterLink href="/privacy">{locale.privacy}</FooterLink>
      </NavR>
    </Foot>
  );
};

const FooterLocale = {
  [localeEN]: {
    since: 'Providing reliable tech since 2024',
    services: 'Services',
    company: 'Company',
    legal: 'Legal',
    subscribe: 'Subscribe!',
    contact: 'Contact',
    terms: 'Terms of use',
    privacy: 'Privacy policy',
  },
  [localeNL]: {
    since: 'Betrouwbare technologie sinds 2024',
    services: 'Diensten',
    company: 'Bedrijf',
    legal: 'Juridisch',
    subscribe: 'Abonneren!',
    contact: 'Contact',
    terms: 'Gebruiksvoorwaarden',
    privacy: 'Privacybeleid',
  },
};
