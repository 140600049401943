'use client';
import { PageLayout } from '@sal-solution/ui-web';
import { FC, PropsWithChildren } from 'react';
import { Footer } from '../Footer/Footer';
import { Navigation } from '../Navigation/Navigation';

export const OfferPageLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <PageLayout navContent={<Navigation />} footerContent={<Footer />}>
      {children}
    </PageLayout>
  );
};
